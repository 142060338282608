@import url(https://fonts.googleapis.com/css2?family=Charmonman:wght@700);
@import url(https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,900;1,300;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Domine:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
.title-container {
  background: url(/static/media/alexander.55be75b4.jpg), #0000007a;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;
}

.bottom-gradient {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff000000, #06090c);
}

@media (min-width: 768px) {
  .title-container {
    position: relative;
    height: 105vh;
    width: 80vw;
    z-index: -10;

    display: flex;
    flex-direction: column;

    margin: 0 10% 20% 10%;

    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    justify-content: center;
    font-family: var(--main-font);
    font-size: 4vw;
  }

  .title-header {
    text-align: center;
    white-space: nowrap;
    top: 70px;
  }

  .title-subheader {
    font-family: var(--subheader-font);
    font-size: 3vw;
    font-weight: 700;
    line-height: 0.8em;

    transform: translate(25%, -150%);
  }
  .title-subheader h4 {
    font-weight: 100;
  }
  .title-subheader h4:nth-child(2) {
    margin: 0 20%;
    font-style: italic;
  }
  .title-subheader h4:nth-child(3) {
    margin: 0 40%;
  }
  .bottom-gradient {
    bottom: 0px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .title-container {
    display: flex;
    flex-direction: row;
    z-index: -10;

    height: 105vh;
    width: 100vw;
  }

  .title-text {
    align-self: center;
    text-align: center;

    margin-left: 8vw;

    font-size: 5vw;
    font-family: var(--main-font);

    transform: translateY(-50%);
  }

  .title-header {
    -webkit-animation: fade-in 1s, appear-right 1s ease-out;
            animation: fade-in 1s, appear-right 1s ease-out;
  }

  .title-subheader {
    margin: -5vw 10vw 0 0;

    font-family: var(--subheader-font);
    font-style: italic;

    -webkit-animation: fade-in 1s, appear-bottom 1s ease-out;

            animation: fade-in 1s, appear-bottom 1s ease-out;
  }
    .bottom-gradient {
    display: none;
  }
}

@-webkit-keyframes appear-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes appear-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes appear-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes appear-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes appear-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes appear-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .about-me-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-picture {
    width: 350px;
    margin-right: 40px;
  }

  .about-text-container {
    width: 500px;
  }
  
  .about-title {
    font-size: 2.5em;
  }

  .about-container span {
    font-style: italic;
  }

  .about-para {
    font-size: 1.2em;
  }

  .about-para p {
    margin: 20px 15px;
  }
}

@media (max-width: 768px) {
  .about-container {
    font-family: var(--subheader-font);
    font-style: normal;
  }

  /* .about-header {
  font-size: 10vw;
  font-weight: 700;

  line-height: 15vw;
  letter-spacing: -5px;

  margin: 20px 0 0 10px;

  transform: translateY(-8vh);
} */

  .about-text-container {
    font-size: 1.2em;
    border-radius: 4px;
  }

  .about-picture {
    display: block;
    width: 80vw;

    margin: auto;
  }

  .about-title {
    margin: 0 0 50px 0;

    font-weight: 400;
    font-size: 2.5em;
    font-weight: 700;
  }

  .about-container span {
    font-style: italic;
  }

  .about-para {
    margin: 0 0;
    font-size: 1.2em;
  }

  .about-para p {
    margin: 20px 15px;
  }
}

@-webkit-keyframes appear-from-left {
  0% {
    transform: translate(-100%, -20px);
  }
  100% {
    transform: translate(0, -20px);
  }
}

@keyframes appear-from-left {
  0% {
    transform: translate(-100%, -20px);
  }
  100% {
    transform: translate(0, -20px);
  }
}

.proj-container {
  font-family: var(--subheader-font);
  font-style: normal;
}

.proj-preview {
  border-radius: 10px;
}

.proj-prev-header {
  text-align: center;
  margin-bottom: 10px;
}

.proj-prev-image {
  width: 100%;
  border-radius: 5px;
}

.proj-prev-date {
}

.proj-prev-description {
}

.proj-prev-tech {
}

.proj-prev-date {
  font-weight: 300;
}

.proj-container hr {
  margin: 20px -10px;
}

.proj-container a {
  width: 100px;

  padding: 10px;

  font-size: 2em;
  text-decoration: none;

  text-align: center;
  justify-content: space-around;

  color: black;
}

@media (min-width: 768px) {
  .skills-container {
    margin-bottom: 100px;
  }

  .skills-content {
  }

  .skills-header {
  }

  .skills-prev-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  .skills-prev {
    width: 300px;

    margin: 20px 20px;
    padding: 20px 0;

    font-family: var(--subheader-font);
    font-size: 1.5em;

    background-color: var(--darker-bg);
  }

  .skills-prev-title {
    text-align: center;
    margin: 0 0 2vw 0;
  }

  .skills-prev-content {
    display: grid;

    margin: 0 0 10px 0;

    grid-template-columns: 10% 20% 10% 22%;
  }

  .skill-prev-icon {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .skill-prev-caption {
    grid-column-start: 4;
    grid-column-end: 5;
  }
}
@media (max-width: 768px) {
  .skills-container {
  }

  /* .skills-header {
  font-family: var(--subheader-font);
  font-size: 4em;
  text-align: center;
} */

  .skills-content {
    display: flex;
    flex-direction: column;
  }

  .skills-prev {
    width: auto;
    height: auto;

    font-family: var(--subheader-font);
    font-size: 4vw;

    margin: 5vw 15vw;
    padding: 20px 0;

    background-color: var(--darker-bg);
  }

  .skills-prev-title {
    text-align: center;
    margin: 0 0 5vw 0;
  }

  .skill-prev-icon {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .skill-prev-caption {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .skills-prev-content {
    display: grid;

    margin: 0 0 10px 0;

    grid-template-columns: 22% 14% 10% 22%;
  }
}

@media (min-width: 768px) {
  .exp-container {
  }
  .exp-header {
  }
  .exp-prev-container {
  }
  .vertical-timeline-element-date {
    color: white;
    font-family: var(--subheader-font);
  }
}
@media (max-width: 768px) {
  .exp-container {
    font-family: var(--subheader-font);
  }

  /* .exp-header {
  text-align: center;
  font-size: 8vw;
  line-height: 11vw;
  letter-spacing: -5px;

  margin: 0 0 40px 0;
  padding: 10px;
} */

  .exp-preview {
    border-radius: 2px;
    padding: 20px;
    margin: 0 0 20px 0;

    color: var(--main-font-color);
  }

  .vertical-timeline-element-date {
    text-align: center;
  }

  .exp-prev-header {
    font-style: normal;
    text-align: center;
  }

  .exp-prev-period {
    font-style: italic;
    opacity: 0.5;
  }
}

@media (min-width: 768px) {
  .contact-header {
    font-size: 2vw;

    font-family: var(--paragraph-font);
    text-align: center;
    padding-top: 20px;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    text-align: center;
    background-color: var(--darker-bg);
    color: whitesmoke;
  }
  .links-box {
    font-size: 5vw;
    margin: 20px;
  }
  .links-box a {
    color: whitesmoke;
  }
  .links-box a:hover {
    color: #6b6b6b;
    transform: scaleX(1.5);
  }
  .links-box a:active :visited {
    color: whitesmoke;
  }
}
@media (max-width: 768px) {
  .contact-header {
    font-size: 5vw;

    font-family: var(--paragraph-font);
    text-align: center;
    padding-top: 20px;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    text-align: center;
    background-color: var(--darker-bg);
    color: whitesmoke;
  }

  .legal {
    display: flex;
    justify-content: center;

    font-size: 2vw;
    font-family: var(--subheader-font);

    margin: 20px 0;

    opacity: 0.4;
  }

  .links-box {
    font-size: 15vw;

    margin: 20px;
  }

  .links-box a {
    color: var(--lighter-bg);
    margin: 2vw;
  }

  a:link {
    color: var(--lighter-bg);
  }
}


.navbar-mobile {
  height: 100px;
  width: 100%;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  top: 0;

  z-index: 10;

  font-family: 'Chivo', sans-serif;
  font-size: 2.5em;

/*  background-color: var(--main-bg-color);
  box-shadow: #090d10 0 2px 20px 2px; */
  color: var(--nav-dark-font-color);

  background: linear-gradient(180deg, #000000 0%, #00000000 100%);
  
  -webkit-animation: appear-from-top 1s ease-in;
  
          animation: appear-from-top 1s ease-in;
}

.navbar-mobile-name {
  font-family: var(--main-font);
  font-size: 0.8em;
  text-align: center;
  white-space: nowrap;
  
  opacity: 0;

  flex-grow: 1;

  transition: all 1s;
}

.sidebar {
  width: 100%;
  color: var(--nav-dark-font-color);
}

.sidebar-toggle {
  height: 40px;
  width: 40px;

  margin: 0 20px 0 20px;

  text-decoration: none;

}

.sidebar-container {
  width: 45vw;
  height: 100vh;

  z-index: 20;

  display: flex;
  justify-content: left;
  position: fixed;

  top: 0;
  left: -100%;

  background-color: var(--main-bg-color);
  border-right: 1px solid var(--main-detail-color);

  transition: 0.2s;
}

.nav-item { 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  padding: 2vh 0 2vh 25px;

  background-color: transparent;

  border: none;
  border-bottom: 1px solid var(--main-detail-color);

  font-family: var(--subheader-font);
  font-size: 6vw;
  color: var(--main-font-color);
  text-decoration: none;
}

.nav-item:first-of-type {
  border-top: 1px solid var(--main-detail-color);
}

.nav-close {
  padding: unset;
  margin: 22px 12px;
  
  font-size: 3.5em;
}

.sidebar-container.active {
  left: 0;
}

.visible {
  opacity: 1;
}

@-webkit-keyframes appear-from-top {
  0% {
    top: -80px;
  }
  100% {
    top: 0;
  }
}

@keyframes appear-from-top {
  0% {
    top: -80px;
  }
  100% {
    top: 0;
  }
}

@media  (max-width: 360px) {
  .navbar-name {
    font-size: 8vw;
  }
}
.navbar-desktop {
  display: flex;
  position: fixed;
  justify-content: flex-end;

  width: 100%;
  height: 60px;
  top: 0;
  z-index: 10;

  background-color: var(--darker-bg);
}

.nav-item-desktop {
  display: flex;
  cursor: pointer;

  font-family: var(--subheader-font);
  font-size: 1.4em;
  font-weight: 500;

  justify-content: center;

  flex-grow: 1;
  padding: 15px 0;
}

.nav-item-desktop:hover {
  background-color: var(--nav-hover);
}
.nav-item-desktop:active {
  background-color: var(--nav-active);
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes menuAppear {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
@keyframes menuAppear {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

/* Do I wanna use this font instead? */

:root {
  --main-font: "Charmonman", cursive;
  --subheader-font: "Chivo", sans-serif;
  --paragraph-font: "Domine", serif;

  --main-font-color: #f0dab3;
  --main-bg-color: #06090c;
  --light-bg-color: #10161d;
  --main-detail-color: #202d36;

  --lighter-bg: #fffdf9;
  --darker-bg: #020203;

  --nav-hover: #111111;
  --nav-active: #3f3930;

  --test: 1px solid;

  --article-bg-alt1: #161f2a;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
} 

body {
  color: #f0dab3;
  color: var(--main-font-color);
  background-color: #06090c;
  background-color: var(--main-bg-color);
}

@media (min-width: 768px) {
  .navbar-mobile {
    display: none;
  }
  
  .title {
    font-family: "Chivo", sans-serif;
    font-family: var(--subheader-font);
    font-size: 5vw;
    font-weight: 700;
    text-align: center;

    line-height: 1.2em;

    padding: 0;
    margin: 15vw 0 8vw 0;
  }
}

@media (max-width: 768px) {
  .paragraph {
    padding: 25vw 5vw 25vw 5vw;
  }

  .navbar-desktop {
    display: none;
  }

  .title {
    font-family: "Chivo", sans-serif;
    font-family: var(--subheader-font);

    font-size: 9vw;
    font-weight: 700;

    line-height: 12vw;

    padding: 0;
    margin: 0 0 10vw 0;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

