@media (min-width: 768px) {
  .contact-header {
    font-size: 2vw;

    font-family: var(--paragraph-font);
    text-align: center;
    padding-top: 20px;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    text-align: center;
    background-color: var(--darker-bg);
    color: whitesmoke;
  }
  .links-box {
    font-size: 5vw;
    margin: 20px;
  }
  .links-box a {
    color: whitesmoke;
  }
  .links-box a:hover {
    color: #6b6b6b;
    transform: scaleX(1.5);
  }
  .links-box a:active :visited {
    color: whitesmoke;
  }
}
@media (max-width: 768px) {
  .contact-header {
    font-size: 5vw;

    font-family: var(--paragraph-font);
    text-align: center;
    padding-top: 20px;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    text-align: center;
    background-color: var(--darker-bg);
    color: whitesmoke;
  }

  .legal {
    display: flex;
    justify-content: center;

    font-size: 2vw;
    font-family: var(--subheader-font);

    margin: 20px 0;

    opacity: 0.4;
  }

  .links-box {
    font-size: 15vw;

    margin: 20px;
  }

  .links-box a {
    color: var(--lighter-bg);
    margin: 2vw;
  }

  a:link {
    color: var(--lighter-bg);
  }
}
