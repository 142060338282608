.title-container {
  background: url("../../images/alexander.jpg"), #0000007a;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;
}

.bottom-gradient {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff000000, #06090c);
}

@media (min-width: 768px) {
  .title-container {
    position: relative;
    height: 105vh;
    width: 80vw;
    z-index: -10;

    display: flex;
    flex-direction: column;

    margin: 0 10% 20% 10%;

    pointer-events: none;
    user-select: none;

    justify-content: center;
    font-family: var(--main-font);
    font-size: 4vw;
  }

  .title-header {
    text-align: center;
    white-space: nowrap;
    top: 70px;
  }

  .title-subheader {
    font-family: var(--subheader-font);
    font-size: 3vw;
    font-weight: 700;
    line-height: 0.8em;

    transform: translate(25%, -150%);
  }
  .title-subheader h4 {
    font-weight: 100;
  }
  .title-subheader h4:nth-child(2) {
    margin: 0 20%;
    font-style: italic;
  }
  .title-subheader h4:nth-child(3) {
    margin: 0 40%;
  }
  .bottom-gradient {
    bottom: 0px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .title-container {
    display: flex;
    flex-direction: row;
    z-index: -10;

    height: 105vh;
    width: 100vw;
  }

  .title-text {
    align-self: center;
    text-align: center;

    margin-left: 8vw;

    font-size: 5vw;
    font-family: var(--main-font);

    transform: translateY(-50%);
  }

  .title-header {
    animation: fade-in 1s, appear-right 1s ease-out;
  }

  .title-subheader {
    margin: -5vw 10vw 0 0;

    font-family: var(--subheader-font);
    font-style: italic;

    animation: fade-in 1s, appear-bottom 1s ease-out;
  }
    .bottom-gradient {
    display: none;
  }
}

@keyframes appear-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes appear-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes appear-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
