.navbar-desktop {
  display: flex;
  position: fixed;
  justify-content: flex-end;

  width: 100%;
  height: 60px;
  top: 0;
  z-index: 10;

  background-color: var(--darker-bg);
}

.nav-item-desktop {
  display: flex;
  cursor: pointer;

  font-family: var(--subheader-font);
  font-size: 1.4em;
  font-weight: 500;

  justify-content: center;

  flex-grow: 1;
  padding: 15px 0;
}

.nav-item-desktop:hover {
  background-color: var(--nav-hover);
}
.nav-item-desktop:active {
  background-color: var(--nav-active);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes menuAppear {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
