@media (min-width: 768px) {
  .about-me-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-picture {
    width: 350px;
    margin-right: 40px;
  }

  .about-text-container {
    width: 500px;
  }
  
  .about-title {
    font-size: 2.5em;
  }

  .about-container span {
    font-style: italic;
  }

  .about-para {
    font-size: 1.2em;
  }

  .about-para p {
    margin: 20px 15px;
  }
}

@media (max-width: 768px) {
  .about-container {
    font-family: var(--subheader-font);
    font-style: normal;
  }

  /* .about-header {
  font-size: 10vw;
  font-weight: 700;

  line-height: 15vw;
  letter-spacing: -5px;

  margin: 20px 0 0 10px;

  transform: translateY(-8vh);
} */

  .about-text-container {
    font-size: 1.2em;
    border-radius: 4px;
  }

  .about-picture {
    display: block;
    width: 80vw;

    margin: auto;
  }

  .about-title {
    margin: 0 0 50px 0;

    font-weight: 400;
    font-size: 2.5em;
    font-weight: 700;
  }

  .about-container span {
    font-style: italic;
  }

  .about-para {
    margin: 0 0;
    font-size: 1.2em;
  }

  .about-para p {
    margin: 20px 15px;
  }
}

@keyframes appear-from-left {
  0% {
    transform: translate(-100%, -20px);
  }
  100% {
    transform: translate(0, -20px);
  }
}
