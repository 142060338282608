@media (min-width: 768px) {
  .skills-container {
    margin-bottom: 100px;
  }

  .skills-content {
  }

  .skills-header {
  }

  .skills-prev-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  .skills-prev {
    width: 300px;

    margin: 20px 20px;
    padding: 20px 0;

    font-family: var(--subheader-font);
    font-size: 1.5em;

    background-color: var(--darker-bg);
  }

  .skills-prev-title {
    text-align: center;
    margin: 0 0 2vw 0;
  }

  .skills-prev-content {
    display: grid;

    margin: 0 0 10px 0;

    grid-template-columns: 10% 20% 10% 22%;
  }

  .skill-prev-icon {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .skill-prev-caption {
    grid-column-start: 4;
    grid-column-end: 5;
  }
}
@media (max-width: 768px) {
  .skills-container {
  }

  /* .skills-header {
  font-family: var(--subheader-font);
  font-size: 4em;
  text-align: center;
} */

  .skills-content {
    display: flex;
    flex-direction: column;
  }

  .skills-prev {
    width: auto;
    height: auto;

    font-family: var(--subheader-font);
    font-size: 4vw;

    margin: 5vw 15vw;
    padding: 20px 0;

    background-color: var(--darker-bg);
  }

  .skills-prev-title {
    text-align: center;
    margin: 0 0 5vw 0;
  }

  .skill-prev-icon {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .skill-prev-caption {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .skills-prev-content {
    display: grid;

    margin: 0 0 10px 0;

    grid-template-columns: 22% 14% 10% 22%;
  }
}
