.proj-container {
  font-family: var(--subheader-font);
  font-style: normal;
}

.proj-preview {
  border-radius: 10px;
}

.proj-prev-header {
  text-align: center;
  margin-bottom: 10px;
}

.proj-prev-image {
  width: 100%;
  border-radius: 5px;
}

.proj-prev-date {
}

.proj-prev-description {
}

.proj-prev-tech {
}

.proj-prev-date {
  font-weight: 300;
}

.proj-container hr {
  margin: 20px -10px;
}

.proj-container a {
  width: 100px;

  padding: 10px;

  font-size: 2em;
  text-decoration: none;

  text-align: center;
  justify-content: space-around;

  color: black;
}
